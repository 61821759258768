<template>
  <div class=" rounded p-4 mb-4 ">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <p>
            <span class="h5 text-main fw-bold align-text-bottom">
              {{ title }}
            </span>
          </p>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <ul class="nav nav-pills ">
          <li class="nav-item profile-nav-item mt-2">
            <router-link :to="`/profile/contracts/${routeName}/active`"
              :class="{ active: isActive(`/profile/contracts/${routeName}/active`) }" class="
              nav-link
              bg-gray
              text-main
              fw-bold
              wc-contract-link
              me-1
              py-1
            ">
              Aktiv müqavilələr
            </router-link>
          </li>
          <li class="nav-item profile-nav-item ms-3 mt-2">
            <router-link :to="`/profile/contracts/${routeName}/archive`"
              :class="{ active: isActive(`/profile/contracts/${routeName}/archive`) }" class="
              nav-link
              bg-gray
              text-main
              fw-bold
              wc-contract-link
              me-1
              py-1
            ">
              Müqavilə arxivi
            </router-link>
          </li>
        </ul>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    data: () => ({
      count: 0,
    }),
    routeName() {
      return this.$route.name;
    },

    title() {
      switch (this.routeName) {
        case "cmtpl":
          return "Avto-İcbari müqavilələr";
        case "travel":
          return "Səfər müqavilələri";
        default:
          "...";
      }
    },
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },

  }
};
</script>